















































































import { Component, Vue, Prop } from 'vue-property-decorator';
import { GraphicalPDFSignatureField } from 'client-website-ts-library/types/Forms/FieldTypes';
import { PDFFormSubfieldChangedData } from '@/app_code/Forms';

interface DrawnPoint {
  x: number;
  y: number;
}

@Component
export default class FormGraphicalPDFSignatureField extends Vue {
  @Prop()
  private readonly def!: GraphicalPDFSignatureField;

  private canvasScale = 12;

  private imageScale = 6;

  private ctx: CanvasRenderingContext2D | null = null;

  private modalActive = false;

  private isDrawing = false;

  private drawnPoints: DrawnPoint[] = [];

  private lastPoint: DrawnPoint | null = null;

  private mode = 'type';

  private typedValue = '';

  private typedSelectedFont = 'Dancing Script';

  private typedAvailableFonts: string[] = [
    'Dancing Script',
    'Shadows Into Light',
    'Marck Script',
  ];

  mounted() {
    const cnv = this.$refs.cnv as HTMLCanvasElement;

    this.ctx = cnv.getContext('2d');

    this.ctx!.fillStyle = '#000';
    this.ctx!.textAlign = 'center';

    this.ctx!.lineWidth = 3;
    this.ctx!.lineCap = 'round';
  }

  get canvasWidth(): number {
    return this.def.Width * this.canvasScale;
  }

  get canvasHeight(): number {
    return this.def.Height * this.canvasScale;
  }

  get previewWidth(): number {
    return this.def.Width * this.imageScale;
  }

  get previewHeight(): number {
    return this.def.Height * this.imageScale;
  }

  private setMode(mode: string): void {
    this.clearCanvas();
    this.mode = mode;
  }

  private clearAll(): void {
    this.typedValue = '';
    this.clearCanvas();
  }

  private clearCanvas(): void {
    this.ctx!.clearRect(0, 0, this.canvasWidth, this.canvasHeight);
  }

  private drawTypedText(): void {
    let fontSize = Math.floor(this.canvasHeight * 0.8);

    // this.ctx!.font = 'bold 30px sans-serif';

    let textMeasurement = null;
    const maxWidth = this.canvasWidth * 0.9;

    for (let i = 0; i < 100; i += 1) {
      this.ctx!.font = `${fontSize}px "${this.typedSelectedFont}"`;

      textMeasurement = this.ctx!.measureText(this.typedValue);

      if (textMeasurement.width <= maxWidth) break;

      fontSize = Math.round(fontSize * 0.95 * 20) / 20;
    }

    this.ctx!.fillText(this.typedValue, this.canvasWidth / 2, (this.canvasHeight / 3) + (fontSize / 3), this.canvasWidth * 0.9);
  }

  private transposeCanvas(): void {
    const img = this.$refs.previewImg as HTMLImageElement;
    const cnv = this.$refs.cnv as HTMLCanvasElement;
    const cnvContext = cnv.getContext('2d');

    if (cnvContext) {
      fetch('https://api.ipify.org?format=json')
        .then((x) => x.json())
        .then(({ ip }) => {
          const stamp = `IP address: ${ip} - Date Signed: ${new Date().toLocaleDateString()} ${new Date().toLocaleTimeString()}`;
          cnvContext.font = '25px arial';
          cnvContext.fillStyle = '#0000FF';
          const width = cnvContext.measureText(stamp).width;
          const height = 25; // gets the font size

          cnvContext.fillText(stamp, cnv.width / 2, cnv.height);
          cnvContext.fillStyle = '#000000';

          img.src = cnv.toDataURL();

          cnv.toBlob((blob) => {
            this.$emit('signed', {
              def: this.def,
              value: blob,
            } as PDFFormSubfieldChangedData);
          }, 'image/png');
        });
    }
  }

  private openModal(): void {
    this.modalActive = true;
  }

  private closeModal(): void {
    if (this.mode === 'type') {
      // Draw the typed text onto the canvas

      this.clearCanvas();

      this.drawTypedText();
    }

    this.transposeCanvas();

    this.modalActive = false;
  }

  private beginDrawing(e: MouseEvent): void {
    this.isDrawing = true;

    this.ctx!.beginPath();

    const point: DrawnPoint = { x: e.offsetX, y: e.offsetY };

    this.drawnPoints = [
      point,
    ];

    this.lastPoint = point;
  }

  private endDrawing(): void {
    this.isDrawing = false;
  }

  private trace(e: MouseEvent): void {
    if (!this.isDrawing) return;

    const point: DrawnPoint = { x: e.offsetX, y: e.offsetY };

    this.drawnPoints.push(point);

    if (this.lastPoint === null) {
      this.ctx!.moveTo(point.x, point.y);
    } else {
      this.ctx!.lineTo(point.x, point.y);
    }

    this.lastPoint = point;

    this.ctx!.stroke();
  }

  get canvasHasContent(): boolean {
    return this.drawnPoints.length !== 0 || (this.mode === 'text' && this.typedValue.length !== 0);
  }
}
